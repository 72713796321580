<template>
  <div class="my-footer">
    <div class="my-footer-content">
      <div class="footer-case">
        <div class="case-item case-compeny">
          <a href="http://www.nigpas.cas.cn/" target="_blank"> <span>&copy;</span>中国科学院南京地质古生物研究所 </a>
        </div>
        <!-- 备案号 -->
        <div class="case-item case-number">
          <a href="http://beian.miit.gov.cn" target="_blank"> 苏ICP备05063896号-8 </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "my-footer",
  data() {
    return {
      telInfo: {
        // connector: 'DrXuHonghe',
        tel: "Phone: +86-25-83282230 ",
        email: "E-mail: hhxu@nigpas.ac.cn",
      },
    };
  },
  mounted() { },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.my-footer {
  background-color: #1890ff;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  left: 0;
  width: 100%;
  color: #ffffff;

  .my-footer-content {
    margin: 0 auto;
    box-sizing: border-box;
    // padding: 45px 0 20px 0;
    padding: 10px 0;

    // font-size: ;
    .footer-first {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .footer-about-us {
        width: 500px;

        .about-us-title {
          font-size: 18px;
        }

        .about-us-desc {
          margin-top: 10px;
          font-size: 14px;
        }
      }

      .footer-tell-us {
        width: 400px;

        .about-tell-title {
          font-size: 18px;
          margin-bottom: 10px;
        }

        .about-tell-desc {
          font-size: 14px;
        }
      }
    }

    .footer-case {
      text-align: center;
      font-size: 12px;
      display: flex;
      justify-content: center;

      .case-item {
        margin-right: 20px;

        a {
          color: #ffffff;

          &:hover {
            color: #ffffff;
          }
        }
      }

      .case-compeny {}

      .case-number {}
    }
  }
}
</style>
