<template>
  <div class="header">
    <div class="header-main flex alc jsb">
      <div class="header-item">
        <div class="header-icon hand" @click="refresh">
          <img src="../../assets/image/logo/logo1.png" alt="" />
          <!-- <img src="../../assets/image/logo/logo3.png" alt="" /> -->
        </div>
        <div class="header-icon">
          <a href="http://www.geobiodiversity.com/home" target="_blank"> <img src="../../assets/image/logo/logo2.png"
              alt="" /></a>
        </div>
      </div>

      <div class="header-item">
        <el-dropdown @command="handleExplore" class="drop">
          <span class="hand" style="color: #fff"> Explore<i class="el-icon-arrow-down el-icon--right"></i> </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="item.type" v-for="(item, index) in exploreList" :key="index">
              {{ item.name }}
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="openUrl('http://references.fossil-ontology.com')">References</div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="openUrl('http://aifossil.fossil-ontology.com')">AIFossil Professional</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="header-item">
        <el-dropdown class="drop" placement="bottom-start">
          <span class="hand" style="color: #fff"> Projects<i class="el-icon-arrow-down el-icon--right"></i> </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="item.name" v-for="(item, index) in ProjectsList" :key="index"
              @click.native="goPath(item)">
              {{ item.name }}
            </el-dropdown-item>
            <!-- 内蒙古要注销 -->
            <el-dropdown-item>
              <div @click="openUrl('http://fossil-ontology.com:8282')">Inner Mongolia paleontology fossil database</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="header-item">
        <el-input v-model="searchVal" class="input-select" @keydown.enter.native="doSearch"
          :placeholder="$t('common.searchValHolder')">
          <el-select v-model="searchName" slot="prepend" @change="setDoserch" :placeholder="$t('add.mapdepot')">
            <el-option :label="$t('add.all')" value="0"></el-option>
            <el-option label="GBDB" value="1"></el-option>
            <el-option :label="$t('add.mapdepot')" value="2"></el-option>
            <el-option :label="$t('add.literature')" value="3"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" @click="doSearch"></el-button>
        </el-input>
      </div>

      <div class="header-item">
        <el-dropdown class="drop">
          <span class="hand" style="color: #fff"> Tools<i class="el-icon-arrow-down el-icon--right"></i> </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="item.name" v-for="(item, index) in ToolsList" @click.native="openUrl(item.src)"
              :key="index">
              {{ item.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- 切换语言 -->
      <div class="header-item header-changelang">
        <span @click="changeLang('zh-cn')" class="hand"> 中文 </span>
        /
        <span @click="changeLang('en-us')" class="hand"> English </span>
      </div>

      <div class="header-item hand">
        <div class="loginbtn" @click="goLogin" v-if="!getUserInfo">
          {{ $t("common.login") }}
        </div>
        <el-dropdown @command="doClickDropMenu" trigger="click" v-else>
          <span>
            <i class="iconfont icon-account-fill header-user-icon"></i>
            <span class="usename" style="color: #ffffff">{{ getUserInfo.nickName }}</span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="user">{{ $t("common.personalInfo") }}</el-dropdown-item>

            <el-dropdown-item command="addImg">{{ $t("common.uploadArticl") }}</el-dropdown-item>
            <el-dropdown-item command="add3D">{{ $t("common.uploadModel") }}</el-dropdown-item>
            <el-dropdown-item command="addPanorama">{{ $t("common.uploadPanorama") }}</el-dropdown-item>
            <el-dropdown-item command="addTomography">{{ $t("common.uploadScan") }}</el-dropdown-item>

            <el-dropdown-item command="mngSysPlat" v-if="showAdmin">
              {{ $t("common.mngSysPlat") }}
            </el-dropdown-item>

            <el-dropdown-item command="doExit">{{ $t("common.signout") }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo, removeUserInfo, removeToken } from "@/utils/auth";
import userApi from "@/api/user";
import commonApi from "@/api/common/index";

import { mapMutations, mapGetters } from "vuex";
export default {
  props: ["showAdmin"],
  data() {
    return {
      exploreList: [
        {
          name: "Images",
          src: "",
          type: 1,
        },
        {
          name: "3DModels",
          src: "",
          type: 2,
        },
        {
          name: "Panorama",
          src: "",
          type: 3,
        },
        {
          name: "Tomography",
          src: "",
          type: 4,
        },
      ],
      ProjectsList: [
        {
          name: "British Geological Survey",
          src: "",
        },
        {
          name: "nternational Palaeoentomological Society",
          src: "",
        },
        {
          name: "Global Boundary Stratotype Sections and Points (GSSPs)",
          src: "",
        },
        {
          name: "fossil taxa dababase",
          src: "dababase",
        },
      ],
      ToolsList: [
        {
          name: "FSIDV",
          src: "http://fsidvis.fossil-ontology.com:8089/",
        },
        {
          name: "Spatiotemporal vis",
          src: "http://www.geobiodiversity.com:5000/",
        },
        {
          name: "Transform",
          src: "http://www.geobiodiversity.com/research/transform",
        },
      ],
      searchVal: "",
      userInfo: "",
      searchName: "1",
    };
  },

  computed: {
    ...mapGetters(["getUserInfo", "getCondition"]),
  },
  created() {
    this.userInfo = getUserInfo();
    this.searchVal = this.$route.query.searchVal || window.sessionStorage.getItem("searchVal");
    this.searchName = this.$route.query.type;
  },
  mounted() {
    this.searchVal = this.$route.query.searchVal || window.sessionStorage.getItem("searchVal");
    this.searchName = this.$route.query.type;
  },
  watch: {
    $route(val) {
      this.searchVal = val.query.searchVal || window.sessionStorage.getItem("searchVal");
    },
  },
  methods: {
    openUrl(src) {
      window.open(src);
    },
    goLogin() {
      this.$router.push({
        path: "/login",
      });
    },
    refresh() {
      window.location.href = 'http://fossil-ontology.com';

    },

    ...mapMutations(["SET_PAGE_LIST", "SET_USER", "SET_SEARCH", "SET_TITLE", "SET_TOTAL"]),
    setDoserch(e) {
      this.searchName = e;
    },
    handleExplore(e) {
      if (!e) {
        return;
      }
      this.getPageList(e);
    },
    async getPageList(type) {
      switch (type) {
        case 1: {
          this.SET_SEARCH({
            searchVal: this.searchVal,
            range: "info",
            from: 1,
          });
          const { data: res1 } = await commonApi.search(this.getCondition);
          this.SET_PAGE_LIST(res1.rows);
          this.SET_TOTAL(res.total);

          this.SET_TITLE("Images");
          break;
        }
        case 2: {
          this.SET_SEARCH({
            searchVal: this.searchVal,
            range: "model_3_d",
            from: 1,
          });
          const { data: res2 } = await commonApi.search(this.getCondition);
          this.SET_PAGE_LIST(res2.rows);
          this.SET_TITLE("3DModels");
          this.SET_TOTAL(res.total);

          break;
        }
        case 3: {
          this.SET_SEARCH({
            searchVal: this.searchVal,
            range: "panorama",
            from: 1,
          });
          const { data: res3 } = await commonApi.search(this.getCondition);
          this.SET_PAGE_LIST(res3.rows);
          this.SET_TITLE("Panorama");
          this.SET_TOTAL(res.total);

          break;
        }
        case 4: {
          this.SET_SEARCH({
            searchVal: this.searchVal,
            range: "tomography",
            from: 1,
          });
          const { data: res4 } = await commonApi.search(this.getCondition);
          this.SET_PAGE_LIST(res4.rows);
          this.SET_TITLE("Tomography");
          this.SET_TOTAL(res.total);
          break;
        }
      }
      this.$router.push({
        path: "/home/list",
        query: {
          set: true,
        },
      });
    },
    goPath(val) {
      if (val.src != "") {
        this.$router.push({
          path: val.src,
        });
      }
    },
    async doSearch() {
      window.sessionStorage.setItem("searchVal", this.searchVal);
      if (this.searchName && this.searchName != 2) {
        return this.$router.push({
          path: "/home/all",
          query: {
            searchVal: this.searchVal,
            type: this.searchName,
          },
        });
      } else {
        this.$router.push({
          path: "/",
          query: {
            searchVal: this.searchVal,
            type: this.searchName,
          },
        });
        this.SET_SEARCH({
          searchVal: this.searchVal,
          range: "*",
          from: 1,
        });
        const { data: res } = await commonApi.search(this.getCondition);
        this.SET_PAGE_LIST(res.rows);
        this.SET_TOTAL(res.total);
        this.SET_SEARCH({
          searchVal: this.searchVal,
          range: "*",
          from: res.from,
        });
      }
    },

    /** 切换语言 */
    changeLang(langKey) {
      if (langKey === this.languageKey) {
        return;
      }
      this.$set(this.$i18n, "locale", langKey);

      localStorage.setItem("language", langKey);
      this.languageKey = langKey;
    },
    doClickDropMenu(e) {
      if (e == "mngSysPlat") {

        return window.open("http://fossil-ontology.com:8082/#/image/info");
      }
      if (e == "doExit") {
        userApi.logout();
        removeUserInfo();
        removeToken();
        this.SET_USER(false);
        location.href = "#/";
      } else {
        this.$router.push({
          path: e,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}

::v-deep .el-input--suffix :focus {
  border: none !important;
}

.header {
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #1890ff;
  color: #ffffff;

  .header-main {
    width: 95%;
    height: 100%;
    margin: 0 auto;

    .header-item {
      height: 100%;
      display: flex;
      align-items: center;

      .loginbtn {
        width: 80px;
        text-align: center;
      }
    }

    .header-icon {
      margin-right: 30px;

      img {
        width: auto;
        height: 47px;
        display: block;
      }
    }

    .input-select {
      width: 505px;

      ::v-deep .el-input__inner:focus {
        border: 1px solid #dcdfe6;
      }

      ::v-deep .el-input-group__prepend {
        border-radius: 22px 0px 0px 22px;
        width: 50px;
        background: #fff;
      }

      ::v-deep .el-input-group__append {
        background: #fff;
        border-radius: 0px 22px 22px 0px;
      }
    }
  }
}

.username {
  color: #ffffff;
}
</style>
