<template>
  <div class="home">
    <!-- <MyHeader :showAdmin="!showAdmin"></MyHeader> -->
    <NewHeader :showAdmin="!showAdmin"></NewHeader>
    <div class="home-content">
      <router-view />
    </div>
    <MyFooter></MyFooter>
  </div>
</template>

<script>
import NewHeader from "@/components/header/newHeader";
import MyHeader from "@/components/header/MyHeader";
import MyFooter from "@/components/footer/MyFooter";

import userApi from "@/api/user";
import { mapMutations } from "vuex";
import { setUserInfo, getToken, getUserInfo } from "@/utils/auth";

export default {
  components: {
    MyHeader,
    MyFooter,
    NewHeader
  },
  data () {
    return {
      showAdmin: false,
    };
  },
  computed: {},
  created () {
    this.getInfo();
  },
  mounted () { },
  methods: {
    ...mapMutations(["SET_USER"]),
    async getInfo () {
      if (getUserInfo() || getToken()) {
        const res = await userApi.getUserInfo();

        if (res.roles.length == 1 && res.roles[0] == "common") {
          this.showAdmin = true;
        }
        this.SET_USER(res.user);
        setUserInfo(res.user);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  height: 100%;
}

.home-content {
  padding-top: 70px;
  height: 100%;
}
</style>
